<template>
  <div  :class="{ 'my-5' : !historyDataTable.length }"  class="P-histories">
    <h4 v-if="!historyDataTable.length" class="mb-5 text-center">Actions History</h4>

    <div v-if="historyDataTable.length">
      <OttUniversalTable 
        :column-config="columnConfigData"
        :selected-arrow-list="selectedArrowList"
        :data="historyDataTable" 
        :is-sub-table="false" 
        :is-multi-select="true" 
        :fixed-first-and-last-column="true"
        :main-data="historyDataTable" 
        :column-with-tree-increment="tableColumnWidth" 
        :is-total-sum="false"
      >
        <!-- 
          @selectRow="selectRow"
          @sortData="sortData"
          @updateColumnWith="updateColumn"
          @updateColumns="updateColumns"
          @saveColumnChangesFromDrag="updateColumnsFromDrag"
        -->
      </OttUniversalTable>

      <div class="P-pagination-cont">
        <OttPaginationForTable 
          v-model="historyLimit" 
          :data-count="historyDataCount" 
          :available-data-count="historyDataTable.length"
          :current-page="historyCurrentPage"
          :page-limit="historyTotalPages" 
          @update="getActionHistory" 
          @updatePage="getActionHistory" />
      </div>
    </div>

    <NoDataInTable v-else />
  </div>
</template>
  
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import TitleComponent from "@/components/TitleComponent";
import OttUniversalTableForComponentsMixin from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";
import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
import NoDataInTable from "@/components/NoDataInTable";

export default {
  name: "HistoriesList",
  components: {
    TitleComponent,
    OttUniversalTable,
    OttPaginationForTable,
    NoDataInTable,
  },
  mixins: [ OttUniversalTableForComponentsMixin ],
  props: {
    selectedUserId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
 
  }),
  computed: {
    ...mapState({
      showActionsHistory: (state) => state.users.showActionsHistory,
      historyDataTable: (state) => state.users.historyDataTable,
      historyDataCount: (state) => state.users.historyDataCount,
      historyCurrentPage: (state) => state.users.historyCurrentPage,
      historyTotalPages: (state) => state.users.historyTotalPages,
      historyQuery: (state) => state.users.historyQueryOptions,
    }),

    historyLimit: {
      set(limit) {
        this.setHistoryQuery({ limit });
      },
      get() {
        return this.historyQuery.limit;
      },
    },
  },

  destroyed() {
    this.setHistoryFilterPage(1)
  },

  data() {
    return {
      columnConfigData: [
        {
          title: "User",
          isTranslate: true,
          defaultHide: true,
          defaultDragIndex: 1,
          mainIndex: 0,
          key: "user",
          style: { minWidth: 165, maxWidth: 165 },
          isShow: true,
          isDrag: true,
          cellView: (row) =>
            `<p class="G-text-table">${row.user.firstname + " " + row.user.lastname
            } </p>`,
        },
        {
          title: "Message",
          isTranslate: true,
          defaultHide: true,
          defaultDragIndex: 2,
          mainIndex: 0,
          key: "message",
          style: { minWidth: 200, maxWidth: 200 },
          isShow: true,
          isDrag: true,
          cellView: (row) => `<p class="G-text-table">${row.message} </p>`,
        },
        {
          title: "Type",
          isTranslate: true,
          defaultHide: true,
          defaultDragIndex: 3,
          mainIndex: 0,
          key: "type",
          style: { minWidth: 90, maxWidth: 90 },
          isShow: true,
          isDrag: true,
          cellView: (row) => `<p class="G-text-table">${row.type} </p>`,
        },
        {
          title: "Action Date",
          isTranslate: true,
          defaultHide: true,
          defaultDragIndex: 4,
          mainIndex: 0,
          key: "actionDate",
          style: { minWidth: 150, maxWidth: 150 },
          isShow: true,
          isDrag: true,
          cellView: (row) => `<p class="G-text-table">${row.updatedAt} </p>`,
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      getUserActionHistory: "users/getUserActionHistory",
    }),
    ...mapMutations({
      usersSetData: "users/setData",
      setHistoryQuery: "users/setHistoryQuery",
      setHistoryFilterPage: 'users/setHistoryFilterPage',
    }),

    getActionHistory() {
      if (this.selectedUserId)
        this.getUserActionHistory({ userId: this.selectedUserId });
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "src/assets/scss/datePicker";
.P-pagination-cont {
  padding-right: 20px;
  padding-bottom: 20px;
}
</style> 
  