<template>
  <selected-filters 
    :selectedList="selectedList"
    @resetData="resetData($event)"
  />
</template>
<script>
  import { mapState, mapMutations, mapActions } from "vuex";
  import SelectedFilters from "@/components/filterComponents/SelectedFilters";

  export default {
    components: { SelectedFilters },
    methods: {
      ...mapMutations({
        filterUsers: 'users/filterUsers'
      }),
      ...mapActions({
        getUsersList: 'users/getUsersList'
      }),
      resetData(type) {
        this.filterModel.resetCurrentData(type)
        this.filterUsers()
        this.getUsersList(this.filter)
      },
    },
    computed: {
      ...mapState({
        filterModel: state => state.users.filterModel,
        filter: state => state.users.filter,
        roles: state => state.roleUser,
        enableList: state => state.users.enableList,
      }),

      getEnableName(){
        let enableText = ''
        if (this.enableList.length) {
          this.enableList.forEach(item=>{
            if(this.filterModel.enable===item.value){
              enableText = item.name
            }
          })
        }
        return enableText
      },

      getRolesName() {
        let roleName = ''
        if (this.filterModel.roles.length) {
          this.filterModel.roles.forEach((item, index) => {
            this.roles.roleList.forEach(itemRole => {
              if (item === itemRole.id) {
                roleName += itemRole.name.replace(/([A-Z])/g, ' $1')
                    .trim()
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ') + (index !== this.filterModel.roles.length - 1 ? ', ' : '')
              }
            })
          })
        }
        return roleName
      },

      selectedList() {
        return [
          {
            ifCondition: !!this.filterModel.search,
            boxText: `<b>Search</b>: ${this.filterModel.search}`,
            resetArgument: 'search'
          },
          {
            ifCondition: !!this.filterModel.roles.length,
            boxText: `<b>Roles</b>: ${this.getRolesName}`,
            resetArgument: 'roles'
          },
          {
            ifCondition: !!this.filterModel.enable,
            boxText: `<b>Access</b>: ${this.getEnableName}`,
            resetArgument: 'enable'
          },
        ]
      }
    }
  };
</script>
