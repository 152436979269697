import { mapActions, mapMutations, mapState } from "vuex";
import { eventBus } from '@/main'
import allCountry from '@/constants/all-countries'

export default {
  data() {
    return {
      defaultColumnConfigData: [],
      columnConfigData: [],
      selectedUser: null,
      warningModalBtnText: "",
      warningModalIsDangerButton: true,
      warningModalIsLoading: false,
      warningModalType: "",
      selectedRowId: null,
      historySelectedList: [],
      bodyContentWidth: null,
      loading: false
    }
  },
  computed: {
    ...mapState({
      filterModel: state => state.users.filterModel,
      users: state => state.users,
      isTableLoading: state => state.users.isTableLoading,

      // important variables vor get list and  change  columns
      isDefault: state => state.users.isDefault,
      dataTable: state => state.users.dataTable,
      filter: state => state.users.filter,
      defaultSettings: state => state.users.defaultSettings,
      updateColumnSettingsCommit: state => state.users.updateColumnSettingsCommit,
      updateColumnsFromBack: state => state.users.updateColumnsFromBack,
      showActionsHistory: state => state.users.showActionsHistory,
      historyDataTable: state => state.users.historyDataTable,
    }),

    filteredDataTable() {
      return this.dataTable.filter(item => item.email !== 'info@ottstream.live')
      /*
        This is just hard code to hide main user from list, because now there is not unic key to 
        hide main user, but if will be unic key you can filter with unic key for example this.dataTable.filter(item => item.type)
       */
    },

    defaultColumnConfigs() {
      return [
        {
          title: "Avatar",
          isTranslate: true,
          style: { minWidth: 90, maxWidth: 90 },
          "defaultHide": true,
          "defaultDragIndex": 0,
          "mainIndex": 0,
          isShow: true,
          key: "avatar",
          isDrag: true,
          cellView: row => `<div class="P-user-page-image" style="background-image: url('${row.avatar ? process.env.VUE_APP_API_URL + 'files/icon/' + row.avatar : ''} ')">
      ${!row.avatar ? `<span  class="icon-no-photos"></span>` : ''}
      </div>`
        },
        {
          title: "Full Name",
          isTranslate: true,
          "defaultHide": true,
          "defaultDragIndex": 1,
          "mainIndex": 1,
          key: "firstname",
          style: { minWidth: 200 },
          isShow: true,
          isDrag: true,
          cellView: row => `<p class="G-text-table">${row.firstname + ' ' + row.lastname} </p>`
        },
        {
          title: "ID",
          isTranslate: true,
          "defaultHide": true,
          "defaultDragIndex": 2,
          "mainIndex": 2,
          key: "number",
          style: { minWidth: 100, maxWidth: 100 },
          isShow: true,
          isDrag: true,
          cellView: row => `<p class="G-text-table">${row.number ? row.number : '-'} </p>`
        },
        {
          title: "Role",
          isTranslate: true,
          "defaultHide": true,
          "defaultDragIndex": 3,
          "mainIndex": 3,
          key: "rolesInfo",
          style: { minWidth: 161 },
          isShow: true,
          isDrag: true,
          cellView: row => `<p class="G-text-table P-role-name">${this.getRolesData(row?.rolesInfo)} </p>`
        },
        {
          title: "Last IP",
          isTranslate: true,
          style: { minWidth: 140 },
          isShow: true,
          key: "lastIp",
          "defaultHide": true,
          "defaultDragIndex": 4,
          "mainIndex": 4,
          isDrag: true,
          cellView: row => `<p class="G-text-table">${this.getLastIP(row?.geoInfo)} </p>`
        },
        {
          title: "Last Geoip Country",
          isTranslate: true,
          style: { minWidth: 200 },
          "defaultHide": true,
          "defaultDragIndex": 5,
          key: "countryCode",
          "mainIndex": 5,
          isShow: true,
          isDrag: true,
          cellView: row => `<p class="G-text-table">${this.getCountryName(row?.geoInfo && row?.geoInfo?.countryCode)} </p>`
        },
      
        {
          title: "Last Geoip City",
          isTranslate: true,
          style: { minWidth: 140 },
          "defaultHide": true,
          "defaultDragIndex": 6,
          "mainIndex": 6,
          key: "geoInfo",
          isShow: true,
          isDrag: true,
          cellView: row => `<p class="G-text-table">${this.getCityName(row?.geoInfo)} </p>`
        },
      
        {
          title: "Last Active Time",
          isTranslate: true,
          "defaultHide": true,
          "defaultDragIndex": 7,
          "mainIndex": 7,
          style: { minWidth: 150 },
          isShow: true,
          isDrag: true,
          key: "lastActiveTime",
          cellView: row => `<p class="G-text-table">${ row.lastActiveTime !== undefined ? row.lastActiveTime : '-'} </p>`,
          sortData: {
            isSort: true,
            sortKey: "lastActiveTime",
            sortValue: ""
          },
        },
      
        {
          title: "Unsuccessful Login Try",
          isTranslate: true,
          style: { minWidth: 100, maxWidth: 100 },
          isShow: true,
          "defaultHide": true,
          "defaultDragIndex": 8,
          "mainIndex": 8,
          key: "loginAttempt",
          isDrag: true,
          cellView: row => `<p class="G-text-table">${row.loginAttempt} </p>`
        },
      
        {
          title: "Create Time",
          isTranslate: true,
          style: { minWidth: 150 },
          isShow: true,
          isDrag: true,
          "defaultHide": true,
          "defaultDragIndex": 9,
          "mainIndex": 9,
          key: "createdAt",
          cellView: row => `<p class="G-text-table">${ row.createdAt !== undefined ? row.createdAt : '-' } </p>`,
          sortData: {
            isSort: true,
            sortKey: "createTime",
            sortValue: ""
          },
        },
      
        {
          title: "Created By",
          isTranslate: true,
          style: { minWidth: 140 },
          "defaultHide": true,
          "defaultDragIndex": 10,
          "mainIndex": 10,
          key: "provider",
          isShow: true,
          isDrag: true,
          cellView: row => `<p class="G-text-table">${row.provider && row.provider.name ? row.provider.name[0].name : '-'} </p>`
        },
      
        {
          title: "Phone Number",
          isTranslate: true,
          "defaultHide": true,
          "defaultDragIndex": 11,
          "mainIndex": 11,
          key: "phone",
          style: { minWidth: 150 },
          isShow: true,
          isDrag: true,
          cellView: row => `<p class="G-text-table">${row.phone ? row.phone.phoneNumber : '-'}  </p>`
        },
      
        {
          title: "Email Address",
          isTranslate: true,
          style: { minWidth: 250 },
          isShow: true,
          "defaultHide": true,
          "defaultDragIndex": 12,
          "mainIndex": 12,
          key: "email",
          isDrag: true,
          cellView: row => `<p class="G-text-table">${row.email} </p>`
        },
      
        {
          title: "Disabled",
          isTranslate: true,
          "defaultHide": true,
          "defaultDragIndex": 13,
          "mainIndex": 13,
          key: "accessEnable",
          style: { minWidth: 150, maxWidth: 100 },
          isShow: true,
          isDrag: true,
          cellView: row => `<div>
                                ${row.accessEnable ? `` : `<span class="G-badge G-disabled-badge">Disabled</span>`}
                            </div>`
        },
      ]
    },

    rowsPerPage: {
      set(limit) {
        this.setLimit(limit)
      },
      get() {
        return this.filter.limit;
      }
    },

    historyDate: {
      set(historyDate) {
        this.setData({ historyDate })
      },
      get() {
        return this.users.historyDate
      }
    },

    bodyContent() {
      const bodyContentElem = document.getElementById('bodyContent')
      return bodyContentElem ? bodyContentElem : null
    },

    menuItemEnable() {
      let list = []

      if (this.permissionIs('updateUser')) {
        list.push(
          {title: "Edit", value: 'edit'},
          {title: "Reset Password", value: 'resetPassword'},
          {title: "Reset Unsuccessful Login Count", value: 'resetLoginCount'},
          {title: "Action History", value: 'actionHistory'},
          {title: "Enable Access", value: 'toggleAccess'}
        )
      }

      if (this.permissionIs('deleteUser')) {
        list.push({title: "Delete", value: 'delete'})
      }

      return list
    },

    menuItemDisable() {
      let list = []

      if (this.permissionIs('updateUser')) {
        list.push(
          {title: "Edit", value: 'edit'},
          {title: "Reset Password", value: 'resetPassword'},
          {title: "Reset Unsuccessful Login Count", value: 'resetLoginCount'},
          {title: "Action History", value: 'actionHistory'},
          {title: "Disable Access", value: 'toggleAccess'}
        )
      }

      if (this.permissionIs('deleteUser')) {
        list.push({title: "Delete", value: 'delete'})
      }

      return list
    },

    menuMultipleItems() {
      let list = []

      if (this.permissionIs('deleteMultiply')) {
        list.push({ title: "Delete", value: 'delete' })
      }

      if (this.permissionIs('updateUser')) {
        list.push(
          { title: "Disable User", value: 'multipleDisable'},
          { title: "Enable User", value: 'multipleEnable'}
        )
      }

      return list
    },
    
    warningModalHeading() {
      return `${this.warningModalBtnText} Users(s) ?`
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> one or more Users! If you're not sure, you can cancel this operation.`
    },
  },
  async created() {
    this.loading = true
    await this.getUsersSettings()
    this.createColumnConfigData()
    this.sortColumn();
    await this.getUsersList(1).finally(() => {
      this.loading = false
    })

    eventBus.$on('sidebarChanged', () => {
      setTimeout(() => {
        if (this.bodyContent) this.bodyContentWidth = this.bodyContent.offsetWidth - 50
      }, 400)
    }) 
  },

  beforeDestroy() {
    this.filterModel.reset()
    this.setData({
      filter: {
        limit: 20,
        sortBy: "_id:desc",
        page: 1
      }
    })
  },

  destroyed() {
    this.setFilterPage(1)
  },

  mounted() {
    if (this.bodyContent) this.bodyContentWidth = bodyContent.offsetWidth - 50
  },

  methods: {
    ...mapActions({
      deleteUsers: 'users/deleteUsers',
      resetPasswordUser: 'users/resetPasswordUser',
      resetUsersLoginCount: 'users/resetUsersLoginCount',
      updateUser: 'users/updateUser',
      getUsersSettings: 'users/getUsersSettings',
      getUserById: 'users/getUserById',
      enableDisable: 'users/enableDisableUsers',
      getUsersListAction: 'users/getUsersList',
      getUserActionHistory: 'users/getUserActionHistory'
    }),

    ...mapMutations({
      setData: 'users/setData',
      setLimit: 'users/setLimit',
      setDataSecondLevels: 'users/setDataSecondLevels',
      generalInfoUserFetch: 'generalInfoUser/fetchData',
      fetchDataRole: 'roleUser/fetchDataRole',
      securityLoginFetchData: 'securityLogin/fetchData',
      setUserId: 'users/setUserId',
      openUsersModal: 'users/openModal',
      disableSaveCloseButton: 'users/disableSaveCloseButton',
      sortColumn: 'users/sortColumn',
      resetFilterModel: 'users/resetFilterModel',
      filterUsers: 'users/filterUsers',
      setFilterPage: 'users/setFilterPage',
      resetHistoryDate: 'users/resetHistoryDate'
    }),

    getRoleData(role) {
      let roleList = ''
      if (role.length) {
        role.forEach((item, index) => {
          roleList += item.name + (index !== role.length - 1 ? ', </br>' : '')
        })
      } else {
        roleList = '-'
      }
      return roleList
    },

    getRolesData(roles) {
      let rolesData = ''
      if (roles) {
        for (let role in roles) {
          if (roles[role]) {
            let name = role;
            if (role === 'advancedCashier') {
              rolesData += ' Advanced Cashier </br>';
            }
            if (role === 'equipmentInstaller') {
              rolesData += ' Equipment Installer </br>';
            } else {
              rolesData += role + ' </br>'
            }
          }
        }
      } else {
        rolesData = '-'
      }

      return rolesData
    },

    getCountryName(countryCode) {
      let name = ''
      allCountry.forEach((item, index) => {
        if (item.value === countryCode) {
          name = item.name
        }
      })
      return name
    },

    getCityName(geoInfo) {
      if (geoInfo) {
        return geoInfo?.city?.names ? geoInfo?.city?.names?.en : geoInfo?.city
      } else {
        return '-'
      }
    },

    getLastIP(geoInfo) {
      if (geoInfo) {
        return geoInfo?.realIp
      } else {
        return '-'
      }
    },

    // TODO CHANGE_SORT
    async resetDataTableSettings() {
      await this.resetDataTable() 
      await this.getUsersSettings()
      this.createColumnConfigData()
    },

    async getUsersList(page) {
      if (page) {
        this.setDataSecondLevels({
          filter: { page }
        })
      }
      await this.getUsersListAction(this.users.filter)
      this.resetSelectedList()
    },

    async updateLimit() {
      await this.$store.dispatch(this.updateColumnsFromBack, this.defaultSettings).then(() => {
        this.createSaveDefaultData();
      })

      this.sortColumn();
      
      await this.getUsersList()
    },

    async selectAction() {
      const [event, data, actionIndex] = arguments;
      this.selectedUser = null;
      this.selectedUser = this.getDataFromActions(data);

      switch(actionIndex) {
        case 'delete':
          this.warningModalBtnText = "Delete";
          this.warningModalIsDangerButton = true;
          this.openWarningModal(actionIndex)
          break;
          
        case 'edit':
          await this.getUserById(this.selectedUser.id).then((data) => {
            this.generalInfoUserFetch(data)
            this.fetchDataRole(data.rolesInfo)
            // this.$store.commit('roleUser/fetchData', data)
            this.securityLoginFetchData(data)
            this.openUsersModal()
            this.setData({ 
              userId: data.id,
              disableButton: false,
              disableFlag: true,
              userData: data
            })
            this.disableSaveCloseButton()
          })
          break;
        
        case 'resetPassword':
          this.warningModalBtnText = "Resset Password";
          this.warningModalIsDangerButton = false;
          this.openWarningModal(actionIndex)       
          break;

        case 'resetLoginCount':
          this.warningModalBtnText = "Resset Login Count";
          this.warningModalIsDangerButton = false;
          this.openWarningModal(actionIndex) 
          break;
        
        case 'actionHistory':
          this.resetHistories()
          this.selectedRowId = this.selectedUser.id
          await this.getUserActionHistory({ userId: this.selectedUser.id })
          this.setData({ showActionsHistory: true })
          break;  
          
        case 'toggleAccess':
          let enableDisable = '';
          if (this.selectedUser.accessEnable) {
            enableDisable = 'disable'  
            this.warningModalIsDangerButton = true;        
          } else {
            enableDisable = 'enable'
            this.warningModalIsDangerButton = false;
          }

          this.warningModalBtnText = enableDisable;
          this.warningModalType = enableDisable;
    
          this.openWarningModal(enableDisable)
          break;
      }
    },

    /**
     * Warning modal functions
     * **/

    openWarningModal(actionName) {
      this.warningModalType = actionName;
      this.warningModalIsLoading = false;      
      switch (this.warningModalType) {        
        case 'delete':
          this.warningModalBtnText = "Delete"
          this.warningModalIsDangerButton = true
          break 

        case 'disable': 
          this.warningModalBtnText = "Disable"
          this.warningModalIsDangerButton = true
          break
        
        case 'enable':
          this.warningModalBtnText = "Enable"
          this.warningModalIsDangerButton = false
          break      
        
        case 'multipleDisable':
          this.warningModalBtnText = "Disable"
          this.warningModalIsDangerButton = true
          break  

        case 'multipleEnable':
          this.warningModalBtnText = "Enable"
          this.warningModalIsDangerButton = false
          break   
      }

      this.setData({showWarningModal : true})
    },

    closeWarningModal() {
      this.warningModalIsLoading = false;
      this.setData({ showWarningModal : false })
    },

    async warningModalBtnFunc() {
      await this.warningModalBtnFunction().finally(() => {
        this.closeWarningModal()
      });
    },

    async warningModalBtnFunction() {
      this.warningModalIsLoading = true
      switch (this.warningModalType) {
        case "delete":
          await this.rejectUser()
          break;

        case "resetPassword":
          await this.resetPasswordUser({email: this.selectedUser.email})
          break;

        case "resetLoginCount":
          await this.resetUsersLoginCount(this.selectedUser.id).then(async ()=>{
            await this.getUsersList()
          })
          break;

        case "enable":
          this.setDataSecondLevels({
            toSaveData: { accessEnable: true }
          })
          await this.updateUser(this.selectedUser.id).then(async () => {
            await this.getUsersList()
          })
          break;

        case "disable":
          this.setDataSecondLevels({
            toSaveData: { accessEnable: false }
          })
          await this.updateUser(this.selectedUser.id).then(async () => {
            await this.getUsersList()
          })
          break;

        case "multipleDisable":
          this.selectedUser = null
            
          if (this.selectedArrowList.length) {    
            await this.enableDisableUsers(false)
          }
          break;

        case "multipleEnable":
          this.selectedUser = null
          if (this.selectedArrowList.length) { 
            await this.enableDisableUsers(true)
          }
          break;  
          
        case "multipleDelete":
          this.selectedUser = null
          await this.rejectUser()
          break;    
      }
    },

    /**
     * Delete user, users
     * **/
    async rejectUser() {
      const deletedUserData = {
        userId : []
      }

      if (this.isSelectedAll && !this.selectedUser) {
        deletedUserData.all = true
      }
      
      if (this.selectedUser) {
        deletedUserData.userId = [];
        deletedUserData.userId.push(this.selectedUser.id)

        await this.deleteUsers(deletedUserData).then(async () => {
          await this.getUsersList()
        })
        return;
      }
      
      if (this.selectedArrowList.length) {
        deletedUserData.userId = [];
        this.selectedArrowList.map(user => {
          deletedUserData.userId.push(user.id)
        })

        await this.deleteUsers(deletedUserData).then(async () => {
          await this.getUsersList()
        })
        return;
      }
    },

    /**
     * Toggle access users
     * **/

    async enableDisableUsers(isEnable) {
      let data = {}

      if (!this.isSelectedAll && this.selectedArrowList.length) {
        data.userId = []
        this.selectedArrowList.map(user => {
          data.userId.push(user.id)
        })
      }

      if (this.isSelectedAll) {
        data.all = true
      }
      
      if (isEnable) {
        data.accessEnable = true
      } else {
        data.accessEnable = false
      }

      await this.enableDisable(data).then(async () => {
        await this.getUsersList()
      })
    },

    async sortColumnData(data) {
      this.sortColumn(data)
      await this.getUsersListAction(this.users.filter)
    },
    async resetData() {
      this.filterModel.reset();
      this.resetFilterModel()
      await this.getUsersListAction(this.filter)
      this.resetSelectedList()
    },
    openModal() {
      this.openUsersModal();
    },

    async exportData() {
      this.filterUsers();
      await this.getUsersListAction({ ...this.users.filter, excel: true })
      this.resetSelectedList()
    },

    resetHistories() {
      this.setData({
        showActionsHistory: false,
        historyDataCount: null,
        historyDataTable: [],
        historyCurrentPage: 0,
        historyTotalPages: 0,
        historyQueryOptions : {
          limit: 20,
          page: 1
        }
      })
      this.selectedRowId = null;
      this.historySelectedList= [];
    }
  },
}
