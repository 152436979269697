<template>
  <div class="P-users-list">
    <div class="G-page-component" :class="{ 'P-filter-open': isOpenFilter }">
      <div class="G-page-title-block G-flex G-justify-between G-align-center">
        <div class="G-component-title">
          <h3 class="secondary--text">Users</h3>
        </div>
        <ott-button
          v-if="permissionIs('createUser')"
          :click="openModal"
          class="primary-sm-btn"
          custom-class="approve"
          icon-before
          text="Add User"
          icon-before-class="mdi mdi-plus d-inline"
        />
      </div>
      <div class="G-component-header page-background">
        <div class="G-table-actions G-flex G-align-center">
          <div class="action-cont-first G-flex G-align-center">
            <OttCheckbox
              @changeCheckbox="selectAllVisibles"
              :indeterminate="selectNotAll"
              :dataVal="selectAllList"
              tooltip="Select all visibles"
            />
            <div class="G-select-all">
              <OttCheckbox
                tooltip="Select all"
                v-model="isSelectedAll"
                @changeCheckbox="selectAll"
              />
            </div>
            <div
              v-if="(selectedArrowList.length || selectedArrowListSub.length) && (permissionIs('updateUser') || permissionIs('deleteMultiply'))"
              class="approve-rej-icon-cont G-flex G-align-center"
            >
              <v-tooltip
                v-if="permissionIs('updateUser')"
                top
                class="ott-tooltip"
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="P-settings"
                    v-bind="attrs"
                    v-on="on"
                    @click="openWarningModal('multipleDisable')"
                  >
                    <span class="mdi mdi-cancel neutral--text P-icon" />
                  </div>
                </template>
                <span>Disable User</span>
              </v-tooltip>
              <v-tooltip
                v-if="permissionIs('updateUser')"
                top
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="P-settings"
                    @click="openWarningModal('multipleEnable')"
                  >
                    <span
                      class="mdi mdi-check-circle-outline neutral--text P-icon"
                    ></span>
                  </div>
                </template>
                <span>Enable User</span>
              </v-tooltip>
              <v-tooltip
                v-if="permissionIs('deleteMultiply')"
                top
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="P-settings"
                    v-bind="attrs"
                    v-on="on"
                    @click="openWarningModal('delete')"
                  >
                    <span class="mdi mdi-delete-outline neutral--text P-icon" />
                  </div>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
            <div
              v-if="(selectedArrowList.length || selectedArrowListSub.length) && permissionIs('updateUser')"
              class="ott-menu select-all-menu-cont P-settings"
            >
              <v-menu offset-y attach=".select-all-menu-cont">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="P-settings"
                    :ripple="false"
                  >
                    <span
                      class="P-icon mdi mdi-dots-vertical G-flex-center"
                      :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}"
                    ></span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, key) of menuMultipleItems"
                    :key="key"
                    :ripple="false"
                  >
                    <v-list-item-title @click="openWarningModal(item.value)">{{
                      item.title
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="G-excel-btn">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="P-settings" v-bind="attrs" v-on="on" @click="exportData">
                    <span class="mdi mdi-file-excel neutral--text P-icon" />
                  </div>
                </template>
                <span>Download Excel</span>
              </v-tooltip>
          </div>
          <OttHideShowColumnsOption
            @updateColumnsFromCheck="updateColumnsFromCheck"
            @resetDataSettingsTable="resetDataTableSettings"
            :column-config="columnConfigData"
            :is-default="isDefault"
            class="P-users-columns"
          />
        </div>
        <div>
          <div
            @click="toggleOpenFilter"
            :class="{ 'P-filter-open': isOpenFilter }"
            class="G-filter-btn"
          >
            <span class="mdi mdi-filter-variant"></span>
            <p>Filter</p>
            <div
              v-if="filterModel.filterCount.length > 0"
              class="active-filter-count"
            >
              <span>{{ filterModel.filterCount.length }}</span>
            </div>
            <span class="mdi mdi-menu-right"></span>
          </div>
        </div>
      </div>
      <div
        class="G-component-body page-background"
        id="bodyContent"
      >
        <div class="G-sub-block-title d-flex align-center">
          <h3 class="secondary--text">Users List</h3>
          <div class="d-flex align-center P-actions-dates">
          
            <div class="G-date-picker mx-3 mb-0">
              <DatePicker
                v-model="historyDate"
                :masks="{ input: ottDateFormat }"
                :max-date="newDateWithMainUserTZ"
                :popover="{ visibility: 'click' }"
                :is-dark="$vuetify.theme.dark"
                is-range
                locale="en"
                class="ott-date-picker"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="ott-date-picker-cont Ott-input-class:">
                    <div class="G-calendar-input">
                      <v-text-field
                        v-model="inputValue.start"
                        v-on="inputEvents.start"
                        outlined
                        dense
                        height="38"
                        label="Actions History From"
                      />
                      <span
                          class="P-date-icon neutral--text"
                          @click="resetHistoryDate"
                          :class="
                            !inputValue.start
                              ? 'mdi mdi-calendar-blank-outline P-calendar'
                              : 'icon-cancel'
                          "
                      ></span>
                    </div>
                    <span class="pick-to neutral--text">To</span>
                    <div class="G-calendar-input">
                      <v-text-field
                        v-model="inputValue.end"
                        v-on="inputEvents.end"
                        outlined
                        dense
                        height="38px"
                        label="Actions History To"
                      />
                      <span
                          @click="resetHistoryDate"
                          :class="
                              !inputValue.start
                              ? 'mdi mdi-calendar-blank-outline P-calendar'
                              : 'icon-cancel'
                          "
                          class="P-date-icon neutral--text"
                      ></span>
                    </div>
                  </div>
                </template>
              </DatePicker>
            </div>
          </div>
        </div>
        <OttUniversalTable
          v-if="!loading"
          :column-config="columnConfigData"
          :selected-arrow-list="selectedArrowList"
          :data="filteredDataTable"
          :is-sub-table="false"
          :is-multi-select="true"
          :fixed-first-and-last-column="true"
          :main-data="filteredDataTable"
          :column-with-tree-increment="tableColumnWidth"
          :is-total-sum="false"
          :selectedRowId="selectedRowId"
          id="usersTable"
          @updateColumns="updateColumns"
          @saveColumnChangesFromDrag="updateColumnsFromDrag"
          @selectRow="selectRow"
          @sortData="sortColumnData"
          @updateColumnWith="updateColumn"
        >
          <template v-if="permissionIs('updateUser')" slot-scope="props" slot="action">
            <v-menu left content-class="P-actions-list">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="selectAction($event, props, item.value)"
                  v-for="(item, i) in props.rowData.accessEnable
                    ? menuItemDisable
                    : menuItemEnable"
                  :key="i"
                >
                  <div>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <div 
            v-if="showActionsHistory" 
            v-click-outside="resetHistories" 
            slot="rowBottomCont"
            class="P-action-histories"
            :style="{width: bodyContentWidth + 'px'}"
          >
            <histories-list :selectedUserId="selectedRowId" />
          </div>    

        </OttUniversalTable>
        <OttPaginationForTable
          v-if="users.usersResult && !loading"
          v-model="rowsPerPage"
          :data-count="users.dataCount"
          :available-data-count="filteredDataTable.length"
          :current-page="users.currentPage"
          :page-limit="users.totalPages"
          @update="updateLimit"
          @updatePage="getUsersList"
        />
        <NoDataInTable v-else />
      </div>
    </div>
    <div
      class="G-filter-component page-background"
      :class="{ 'P-filter-open': isOpenFilter }"
    >
      <div class="G-filter-header G-flex G-align-center G-justify-between">
        <p class="secondary--text">Filter by</p>
        <span class="mdi mdi-close neutral--text" @click="closeFilter"> </span>
      </div>
      <div class="G-filter-body">
        <FilterData @resetSelectedList="resetSelectedList" />
      </div>
      <div class="G-filter-footer">
        <ott-button
          :click="resetData"
          class="primary-lg-btn"
          text="Reset Filters"
        >
        </ott-button>
      </div>
    </div>
    <PageLoader v-if="isTableLoading || loading"/>
    <WarningModal
      v-if="users.showWarningModal"
      :isModalOpen="users.showWarningModal"
      :modalHeading="warningModalHeading"
      :modalText="warningModalText"
      :btnText="warningModalBtnText"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="setData({ showWarningModal: false })"
      @btnFunction="warningModalBtnFunc"
    />
  </div>
</template>
<script>
import UsersMixin from "./UsersMixin";
import FilterMixin from "@/mixins/FilterMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import OttUniversalTableForComponentsMixin from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";
import ColumnConfigMixin from "@/mixins/ColumnConfigMixin";
// components
import WarningModal from "@/components/WarningModal";
import HistoriesList from "./components/HistoriesList";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttHideShowColumnsOption from "@/components/OttUniversalTable/OttHideShowColumnsOption";
import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
import FilterData from "@/views/users/components/FilterData";
import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
import PageLoader from "@/components/pageLoader/PageLoader";
import NoDataInTable from "@/components/NoDataInTable";
import DatePicker from "v-calendar/src/components/DatePicker";

export default {
  name: "Users",
  components: {
    NoDataInTable,
    WarningModal,
    HistoriesList,
    PageLoader,
    OttPaginationForTable,
    FilterData,
    OttUniversalTable,
    OttHideShowColumnsOption,
    OttCheckbox,
    OttButton,
    DatePicker
  },
  mixins: [ UsersMixin, DateFormatMixin, ConvertTimeZoneMixin, OttUniversalTableForComponentsMixin, FilterMixin, ColumnConfigMixin ],
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";

.theme--dark {
  .icon-no-photos {
    color: $white !important;
  }
}

.P-user-page-image {
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
  fill: #0a3c68;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-no-photos {
    color: $neutral-color;
    display: block;
    font-size: 40px;
  }
}

.P-access-users {
  span {
    padding: 3px 10px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    font-size: $txt14;
  }

  .P-enable-user {
    background-color: $green-lighter;
  }

  .P-disable-user {
    background-color: $danger-color9;
  }
}
</style>
<style lang="scss" scoped>
@import "src/assets/scss/variables.scss";

.P-filter-open {
  .mdi-menu-right {
    transform: rotate(180deg);
  }
}

.P-users-list::v-deep {
  position: relative;
  .P-table-even {
    &.P-sub-table {
      .P-sub-table-bg {
        position: unset;
      }
    }
  }
}

.G-excel-btn {
  border-left: unset;
  padding-left: unset;
}

.action-cont-first {
  border-right: 1.5px solid $neutral-color;
  height: 36px;
  align-items: center;

  .v-menu__content {
    max-width: 190px !important;
  }
}

.P-users-columns::v-deep {
  padding-left: 10px;
  .P-column-configs {
    max-width: 477px;
  }

  .P-column-configs ul li {
    width: 50%;
  }

  .v-menu__content {
    max-height: 360px !important;
  }
}

.P-main-column {
  li {
    background-color: $darkBg-color12MD;
  }
}

.theme--dark {
  .P-users-list::v-deep {
    .P-main-column {
      &:hover {
        li {
          background-color: $darkBg-color12MD !important;
        }
      }
    }
  }
}

.theme--dark.P-actions-list {
  .v-list {
    background-color: #3a3b3f !important;
  }
}

.P-action-histories {
  padding: 0 25px;
  position: sticky;
    left: 0;
    right: 0;
  // box-shadow: inset 0px 0px 6px 3px grey;
  background-color: white;
  z-index: 1;
}

::v-deep {
  .v-text-field__details, .v-messages {
    display: none;
  }
}

.P-actions-dates {
  height: 40px;
}
</style>

<style>
  .P-role-name {
    text-transform: capitalize;
  }
</style>