<template>
  <div class="P-filter-provider">
    <div class="P-input-block">
      <ott-input
        v-model="filterModel.search"
        @timeoutInput="filterData('search')"
        :timeoutInput="true"
        label="Search"
        prependInnerIcon="mdi mdi-magnify  neutral--text"
      ></ott-input>
    </div>
    <div class="P-input-block">
      <ott-select
        v-model="filterModel.roles"
        @emitFunc="filterData('roles')"
        :items="rolesList"
        :multiple="true"
        :persistentHint="true"
        label="Roles"
        selected-text="name"
      >
      </ott-select>
    </div>

    <ott-select
      v-model="filterModel.enable"
      @emitFunc="filterData('enable')"
      :items="enableList"
      :clear="true"
      :persistentHint="true"
      label="Access"
      selected-text="name"
    >
    </ott-select>
    <SelectedFiltersList v-if="filterModel.filterCount.length" />
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import SelectedFiltersList from "@/views/users/components/SelectedFiltersList";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";

export default {
  components: {
    OttCheckbox,
    SelectedFiltersList,
    OttSelect,
    OttInput,
  },
  data() {
    return {
      rolesList: [],
    };
  },

  computed: {
    ...mapState({
      filterModel: (state) => state.users.filterModel,
      filter: (state) => state.users.filter,
      enableList: (state) => state.users.enableList,
      roles: (state) => state.roleUser,
    }),
  },

  async mounted() {
    await this.getRoleList();
  },

  methods: {
    ...mapActions({
      getUsersList: 'users/getUsersList',
      getRoleListAction: 'roleUser/getRoleList',
    }),

    ...mapMutations({
      filterUsers: 'users/filterUsers',
      setFilterPage: 'users/setFilterPage',
      sortColumn: 'users/sortColumn',
    }),

    async filterData(type) {
      switch (type) {
        case "search": {
          if (!this.filterModel.search) {
            this.filterModel.resetCurrentData(type);
          }
          break;
        }
        case "roles": {
          if (!this.filterModel.roles.length) {
            this.filterModel.resetCurrentData(type);
          }
          break;
        }
        case "enable": {
          if (!this.filterModel.enable) {
            this.filterModel.resetCurrentData(type);
          }
          break;
        }
      }
      
      this.setFilterPage(1)
      this.filterModel.getFilterCount();
      this.filterUsers();
      this.sortColumn();
      await this.getUsersList(this.filter)
      this.$emit('resetSelectedList')
    },
    async getRoleList() {
      await this.getRoleListAction().then((data) => {
        this.rolesList = this.roles.roleList.map((item) => {
          return {
            name: item.name.replace(/([A-Z])/g, ' $1')
                    .trim()
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' '),
            value: item.id,
            id: item.id
          };
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.P-mb-filter {
  margin-bottom: 26px;
  position: relative;
}

.P-filter-provider::v-deep {
  .v-text-field__details,
  .v-messages {
    display: none;
  }
}

.P-input-block:first-child {
  margin-bottom: 20px;
}

.P-input-block:nth-child(2) {
  margin-bottom: 10px;
}
</style>
